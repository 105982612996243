h1 {
  margin-top: 5px;
  font-family: 'Merriweather Sans', sans-serif;
}

h2 {
  color: #09d3ac;
  margin-top: 5px;
  font-family: 'Source Code Pro', monospace;
}

h3 {
  font-weight: 400;
  font-family: 'Source Code Pro', monospace;
}

p {
  font-family: 'Source Code Pro', monospace;
}

a {
  text-decoration: none;
  color: white;
}

.App-logo {
  height: 3vmin;
}
